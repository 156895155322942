import axiosInstance from 'axios';
// moment js timezone
import moment from 'moment-timezone';
// config
import { HOST_API } from '../config';
// redux
import { dispatch } from '../redux/store';
import { updateMaintenanceStatus } from 'src/redux/slices/maintenance/maintenance';
import { addError } from 'src/redux/slices/error/error';
import { logout } from '../redux/slices/auth/auth';
import { addNotification } from 'src/redux/slices/notifications/notifications';
// ----------------------------------------------------------------------


const axios = axiosInstance.create({
    baseURL: HOST_API,
    timeout: 30000,
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
    headers: {
        'X-Timezone': moment.tz.guess()
    }
});

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log(error.response)
        if (error.response && error.response.status === 503) {
            // UNDER MAINTENANCE
            dispatch(updateMaintenanceStatus(true));
        } else if (error.response && error.response.status === 401) {
            // UNAUTHORIZE
            dispatch(addNotification("you_are_logout", "error", "you_are_logout"));
            dispatch(logout());
        } else if (error.response && ([400, 500, 404].includes(error.response.status))) {
            const regexPatternSync = /^\/integrations\/[^/]+\/synchronize$/;
            const regexPatternInitial = /^\/integrations\/[^/]+\/initialize$/;

            // customize notification for wizishop (/shops and connects)
            if (error.response.status === 400 && (error.response?.config?.url.includes('/wizishop/shops') || error.response?.config?.url.includes('/wizishop/connect')) && error.response?.data?.violations?.some((x: any) => x?.name === 'email')) {
                dispatch(addNotification("Cannot connect, please verify your email/password", "error", "integration.wizishop.error.can_not_connect"))
            } else if (error.response.status === 400 && (regexPatternSync.test(error.response.config.url) || regexPatternInitial.test(error.response.config.url)) && error.response?.data?.violations?.some((x: any) => x?.name === 'order_id')) {
                dispatch(addNotification("Order not found, make sure you typed the right number", "error", "integration.order_id_not_found"))
            } else
                // ERRORS
                dispatch(addError({ ...error.response.data, statusCode: error.response.status }));
        } else dispatch(updateMaintenanceStatus(false));

        return Promise.reject((error.response) || 'Something went wrong');
    }
);

export default axios;
